import { SIZES } from './sizes';

export const SCREENS = {
    mobileAndUpper: `@media (min-width: ${SIZES.mobile}px)`,
    mobileAndLower: `@media (max-width: ${SIZES.mobile - 1}px)`,

    tablet: `@media (min-width: ${SIZES.tablet}px) and (max-width: ${SIZES.desktopAverage - 1}px)`,
    tabletAndUpper: `@media (min-width: ${SIZES.tablet}px)`,
    tabletAndLower: `@media (max-width: ${SIZES.tablet - 1}px)`,

    desktopAverage: `@media (min-width: ${SIZES.desktopAverage}px) and (max-width: ${SIZES.desktopMedium - 1}px)`,
    desktopAverageAndUpper: `@media (min-width: ${SIZES.desktopAverage}px)`,
    desktopAverageAndLower: `@media (max-width: ${SIZES.desktopAverage - 1}px)`,

    desktopMedium: `@media (min-width: ${SIZES.desktopMedium}px) and (max-width: ${SIZES.desktopLarge - 1}px)`,
    desktopMediumAndUpper: `@media (min-width: ${SIZES.desktopMedium}px)`,
    desktopMediumAndLower: `@media (max-width: ${SIZES.desktopMedium - 1}px)`,

    desktopLargeAndUpper: `@media (min-width: ${SIZES.desktopLarge}px)`,
};
