import React, { useState } from 'react';

import { Cross } from '@assets/svg/Cross';
import { COLORS } from '@constants/colors';
import { INoteProps } from '@components/ClientNote/INoteProps';

import * as S from './styled';

type INoteBannerProps = INoteProps;

const NoteBanner = ({ note, hidden, onClose }: INoteBannerProps): JSX.Element => {
    const [visible, setVisible] = useState<boolean>(!hidden);

    if (!visible) {
        return <></>;
    }

    const onClickClose = (): void => {
        setVisible(false);
        onClose(note);
    };

    return (
        <S.Container>
            {note.closable && (
                <S.CloseButton onClick={onClickClose}>
                    <Cross fill={COLORS.white} />
                </S.CloseButton>
            )}

            <S.QuillContainer className="ql-editor" dangerouslySetInnerHTML={{ __html: note.message ?? '' }} />
        </S.Container>
    );
};

export default NoteBanner;
