import React from 'react';

// Classname attribute is mandatory to wrap React Component in Styled Component
export interface ISvgIcon extends React.SVGProps<SVGSVGElement> {
	verticalOffset?: number | undefined;
	horizontalOffset?: number | undefined;
	scale?: number | undefined;
	fill?: string | undefined;
	className?: string | undefined;
	borderType?: 'regular' | 'solid';
}

interface ISvg extends ISvgIcon {
	width: string | number;
	height: string | number;
	viewBox: string;
	children: React.ReactNode;
}

const Svg = ({width, height, viewBox, scale, fill, children, className, ...rest}: ISvg): JSX.Element => (
	<svg
		width={`${width}`}
		height={`${height}`}
		viewBox={`${viewBox}`}
		fill={`${fill}`}
		xmlns='http://www.w3.org/2000/svg'
		style={{transform: `scale(${scale || 1})`}}
		className={className || ''}
		{ ...rest}
	>
		{children}
	</svg>
);

export default Svg;
