import React, { ReactNode, useState } from 'react';
import Head from 'next/head';

interface IHeadContext {
    pageTitle: string | undefined;
    setPageTitle(pageTitle: string | undefined): void;
}

const HeadContext = React.createContext<IHeadContext>({} as IHeadContext);
const { Provider, Consumer } = HeadContext;

interface IProps {
    children: ReactNode;
}

const HeadProvider = ({ children }: IProps): JSX.Element => {
    const [pageTitle, setPageTitle] = useState<string | undefined>('Santé Académie');

    return (
        <Provider
            value={{
                pageTitle,
                setPageTitle,
            }}
        >
            <Head>
                <link rel="preload" href="/fonts/Montserrat-Black.ttf" as="font" crossOrigin="" />
                <link rel="preload" href="/fonts/Montserrat-BlackItalic.ttf" as="font" crossOrigin="" />
                <link rel="preload" href="/fonts/Montserrat-Bold.ttf" as="font" crossOrigin="" />
                <link rel="preload" href="/fonts/Montserrat-BoldItalic.ttf" as="font" crossOrigin="" />
                <link rel="preload" href="/fonts/Montserrat-ExtraBold.ttf" as="font" crossOrigin="" />
                <link rel="preload" href="/fonts/Montserrat-ExtraBoldItalic.ttf" as="font" crossOrigin="" />
                <link rel="preload" href="/fonts/Montserrat-ExtraLight.ttf" as="font" crossOrigin="" />
                <link rel="preload" href="/fonts/Montserrat-ExtraLightItalic.ttf" as="font" crossOrigin="" />
                <link rel="preload" href="/fonts/Montserrat-Italic.ttf" as="font" crossOrigin="" />
                <link rel="preload" href="/fonts/Montserrat-Light.ttf" as="font" crossOrigin="" />
                <link rel="preload" href="/fonts/Montserrat-LightItalic.ttf" as="font" crossOrigin="" />
                <link rel="preload" href="/fonts/Montserrat-Medium.ttf" as="font" crossOrigin="" />
                <link rel="preload" href="/fonts/Montserrat-MediumItalic.ttf" as="font" crossOrigin="" />
                <link rel="preload" href="/fonts/Montserrat-Regular.ttf" as="font" crossOrigin="" />
                <link rel="preload" href="/fonts/Montserrat-SemiBold.ttf" as="font" crossOrigin="" />
                <link rel="preload" href="/fonts/Montserrat-SemiBoldItalic.ttf" as="font" crossOrigin="" />
                <link rel="preload" href="/fonts/Montserrat-Thin.ttf" as="font" crossOrigin="" />
                <link rel="preload" href="/fonts/Montserrat-ThinItalic.ttf" as="font" crossOrigin="" />
                <title>{pageTitle}</title>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
                />
                <link href="https://cdn.quilljs.com/1.3.6/quill.snow.css" rel="stylesheet" />
            </Head>
            {children}
        </Provider>
    );
};

export { HeadContext };
export default HeadProvider;
export { Consumer as HeadConsumer };
