import React, { useState, ReactNode, useEffect, useContext } from 'react';

import { IClient, ContentStudioApiContext } from '@santeacademie/contentstudio-api-client';

interface IClientContext {
    client?: IClient;
}

const ClientContext = React.createContext<IClientContext>({} as IClientContext);
const { Provider, Consumer } = ClientContext;

interface IClientProviderProps {
    clientId?: string;
    children: ReactNode;
}

const ClientProvider = ({ clientId, children }: IClientProviderProps): JSX.Element => {
    const { clientFind } = useContext(ContentStudioApiContext);
    const [client, setClient] = useState<IClient | undefined>();

    useEffect(() => {
        (async function fetchClient() {
            if (clientId) {
                const remoteClient: IClient = await clientFind({ clientId });
                setClient(remoteClient);
            }
        })();
    }, []);

    return (
        <Provider
            value={{
                client,
            }}
        >
            {children}
        </Provider>
    );
};

export { ClientContext };
export default ClientProvider;
export { Consumer as ClientConsumer };
