import styled from 'styled-components';

import { COLORS } from '@constants/colors';
import { SCREENS } from '@constants/screens';
import { SIZES } from '@constants/sizes';

export const Container = styled.div`
    background: ${COLORS.pink};
    position: relative;

    .ql-editor * {
        color: white;
    }
`;

export const CloseButton = styled.button`
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
`;

export const ModalContainer = styled.div`
    display: flex;
    flex-flow: column;
    background-color: #ffffff;
    border-radius: 15px;

    width: 100vw;
    max-width: ${SIZES.mobile}px;
    max-height: 80%;
    height: initial;

    ${SCREENS.mobileAndLower} {
        width: 100vw;
        max-height: 100%;
        max-width: initial;
        height: initial;
    }

    ${SCREENS.mobileAndLower} {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
`;

export const ModalBody = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 20px 20px 20px 20px;
`;
