import React, { ReactElement } from 'react';

import { useCookies } from 'react-cookie';

import { IClientNote } from '@santeacademie/contentstudio-api-client';

import NoteBanner from '@components/ClientNote/NoteBanner/NoteBanner';
import NotePopup from '@components/ClientNote/NotePopup/NotePopup';
import NoteFullscreen from '@components/ClientNote/NoteFullscreen/NoteFullscreen';
import { INoteProps } from '@components/ClientNote/INoteProps';

import * as S from './styled';

type IClientNoteRouterProps = {
    notes: IClientNote[];
};

const CLIENT_NOTES_COOKIE = 'client_notes';

const ClientNoteRouter = ({ notes }: IClientNoteRouterProps): JSX.Element => {
    const [cookie, setCookie] = useCookies([CLIENT_NOTES_COOKIE]);

    const getCookieNotes = (): number[] =>
        (cookie[CLIENT_NOTES_COOKIE] ?? '').split(';').map((item: string) => {
            const itemInt: number = parseInt(item, 10);
            return Number.isNaN(itemInt) ? 0 : itemInt;
        });

    const onClose = (note: IClientNote): void => {
        const cookieNotes: number[] = getCookieNotes();

        if (!cookieNotes.includes(note.id)) {
            cookieNotes.push(note.id);
        }

        setCookie(CLIENT_NOTES_COOKIE, cookieNotes.join(';'), {
            path: '/',
            maxAge: note.rememberClose,
            sameSite: true,
        });
    };

    return (
        <S.Container>
            {notes.map((note: IClientNote): ReactElement | null => {
                if (!note.urimatch || document.location.href.match(note.urimatch) || document.location.href.indexOf(note.urimatch) >= 0) {
                    const hidden: boolean = note.rememberClose !== 0 && getCookieNotes().includes(note.id);
                    const props: INoteProps = {
                        key: note.id,
                        note,
                        hidden,
                        onClose,
                    };

                    let NoteComponent: ((props: INoteProps) => JSX.Element) | null = null;

                    switch (note.displayMode) {
                        case 'banner':
                            NoteComponent = NoteBanner;
                            break;
                        case 'popup':
                            NoteComponent = NotePopup;
                            break;
                        case 'fullscreen':
                            NoteComponent = NoteFullscreen;
                            break;
                        default:
                            NoteComponent = () => <></>;
                            break;
                    }

                    return NoteComponent && <NoteComponent {...props} />;
                }

                return null;
            })}
        </S.Container>
    );
};

export default ClientNoteRouter;
