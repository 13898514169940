import React from 'react';

import Svg, {ISvgIcon} from './Svg';

const Cross = ({scale, fill}: ISvgIcon): JSX.Element => (
	<Svg width={24} height={24} viewBox='0 0 24 24' scale={scale} fill={fill}>
		<path d='M16.8332 6.2002C17.1001 5.93327 17.5329 5.93327 17.7998 6.2002C18.0667 6.46712 18.0667 6.8999 17.7998 7.16683L7.16683 17.7998C6.8999 18.0667 6.46712 18.0667 6.2002 17.7998C5.93327 17.5329 5.93327 17.1001 6.2002 16.8332L16.8332 6.2002Z' />
		<path d='M6.2002 7.16683C5.93327 6.8999 5.93327 6.46712 6.2002 6.2002C6.46712 5.93327 6.8999 5.93327 7.16683 6.2002L17.7998 16.8332C18.0667 17.1001 18.0667 17.5329 17.7998 17.7998C17.5329 18.0667 17.1001 18.0667 16.8332 17.7998L6.2002 7.16683Z' />
	</Svg>
);

export {Cross};
