import styled from 'styled-components';
import MaterialModal from '@mui/material/Modal';
import { SCREENS } from '@constants/screens';

export const Container = styled(MaterialModal)`
    inset: auto !important;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0px !important;
    right: 0px !important;
    left: 0px !important;
    bottom: 0px !important;
    ${SCREENS.mobileAndLower} {
        top: 5% !important;
        right: 0px !important;
        left: 0px !important;
        bottom: 0px !important;
    }

    @supports (padding-bottom: env(safe-area-inset-bottom)) {
        padding-bottom: env(safe-area-inset-bottom);
    }
`;

export const Body = styled.div`
    //position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    ${SCREENS.mobileAndLower} {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        align-items: stretch;
    }
`;
