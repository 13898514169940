import React, { useState } from 'react';

import { Cross } from '@assets/svg/Cross';
import { COLORS } from '@constants/colors';
import { Modal } from '@components/Modal/Modal';
import { INoteProps } from '@components/ClientNote/INoteProps';

import * as S from './styled';

type INotePopupProps = INoteProps;

const NotePopup = ({ note, hidden, onClose }: INotePopupProps): JSX.Element => {
    const [visible, setVisible] = useState<boolean>(!hidden);

    if (!visible) {
        return <></>;
    }

    const onClickClose = (): void => {
        setVisible(false);
        onClose(note);
    };

    return (
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        <Modal isShowing={visible} onClose={() => {}}>
            <S.ModalContainer>
                <S.ModalBody>
                    {note.closable && (
                        <S.CloseButton onClick={onClickClose}>
                            <Cross fill={COLORS.blueDarker} />
                        </S.CloseButton>
                    )}

                    <div className="ql-editor" dangerouslySetInnerHTML={{ __html: note.message ?? '' }} />
                </S.ModalBody>
            </S.ModalContainer>
        </Modal>
    );
};

export default NotePopup;
