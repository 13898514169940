import React, { ReactNode, MouseEvent, useState } from 'react';

interface IOutsideClickContext {
    event: any;
}

const OutsideClickContext = React.createContext<IOutsideClickContext>({} as IOutsideClickContext);
const { Provider, Consumer } = OutsideClickContext;

interface IProps {
    children: ReactNode;
}

const OutsideClickProvider = ({ children }: IProps): JSX.Element => {
    const [event, setEvent] = useState<MouseEvent<HTMLDivElement> | null>(null);

    const onClick = (e: MouseEvent<HTMLDivElement>) => {
        setEvent(e);
    };

    return (
        <Provider
            value={{
                event,
            }}
        >
            <div onClickCapture={onClick}>{children}</div>
        </Provider>
    );
};

export { OutsideClickContext };
export default OutsideClickProvider;
export { Consumer as OutsideClickConsumer };
