/**
 * @deprecated
 */

const COLORS = {
    // Statics
    pink: 'rgb(248,79,146)',
    blueDarker: 'rgb(24,31,68)',
    white: 'rgb(255,255,255)',
    black: 'rgb(0,0,0)',
    turquoise: 'rgb(63,224,216)',
    yellow: 'rgb(237,184,63)',
    yellowLighter: 'rgb(253, 249, 240)',
    greyLighter: 'rgb(250,250,250)',
    grey: 'rgb(136,143,182)',
    red: 'rgb(248,79,79)',

    // Dynamics
    opacify: (colorRGB: string, ratio: number): string =>
        colorRGB.replace(')', `,${ratio})`).replace('rgb', 'rgba'),
};

export { COLORS };
