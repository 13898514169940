export enum AccessControlScope {
    PUBLIC = 'public',
    PRIVATE = 'private',
}

export type AccessControlRoute = {
    path: string;
    scope: AccessControlScope;
};

const accessControlList: AccessControlRoute[] = [
    { path: '/login', scope: AccessControlScope.PUBLIC },
    { path: '/reset', scope: AccessControlScope.PUBLIC },
    { path: '/setup', scope: AccessControlScope.PUBLIC },
    { path: '/trusted-idp', scope: AccessControlScope.PUBLIC },
];

export const isPublicPath = (path?: string): boolean => {
    path = path ?? document.location.href;

    for (const routeIndex in accessControlList) {
        const route: AccessControlRoute = accessControlList[routeIndex];

        if (path.match(route.path) && route.scope === AccessControlScope.PUBLIC) {
            return true;
        }
    }

    return false;
};

export const isPrivatePath = (path?: string): boolean => {
    return !isPublicPath(path);
};
