import styled from 'styled-components';

import { COLORS } from '@constants/colors';

export const Container = styled.div`
    background: ${COLORS.white};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100000;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1;

    .ql-editor {
        height: inherit;
    }

    .ql-editor * {
        color: ${COLORS.blueDarker};
    }
`;

export const CloseButton = styled.button`
    position: absolute;
    top: 40px;
    right: 40px;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
`;
