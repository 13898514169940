import React, { useContext } from 'react';

import { useRouter } from 'next/router';
import { AppProps } from 'next/app';
import { ToastContainer } from 'react-toastify';
import { secondsToMilliseconds } from 'date-fns';

import { ContentStudioApiProvider } from '@santeacademie/contentstudio-api-client';

import HeadProvider from '@stores/HeadContext';
import ClientProvider from '@stores/ClientContext';
import { CookieStoreContext } from '@stores/CookieStoreContext';
import OutsideClickProvider from '@stores/OutsideClickContext';

import Body from '../pages/body';

const Html = ({ Component, pageProps }: AppProps): JSX.Element => {
    const router = useRouter();
    const { removeLearnerIdentifierCookie } = useContext(CookieStoreContext);

    return (
        <ContentStudioApiProvider
            backstageApiBaseUrl={process.env.NEXT_PUBLIC_BACKSTAGE_API_BASE_URL || ''}
            apiBaseUrl={process.env.NEXT_PUBLIC_API_BASE_URL || ''}
            authApiBaseUrl={process.env.NEXT_PUBLIC_CROSSGUARD_URL || ''}
            clientId={process.env.NEXT_PUBLIC_CROSSGUARD_CLIENT_ID || ''}
            clientSecret={process.env.NEXT_PUBLIC_CROSSGUARD_CLIENT_SECRET || ''}
            onTokenUpdate={() => { }}
            onGlobalFail={() => { }}
            onAuthFail={() => {
                removeLearnerIdentifierCookie();
                document.location.href = '/login?redirect=' + encodeURIComponent(router.asPath);
            }}
            timeout={secondsToMilliseconds(120)}
            accessTokenProps={null}
            refreshTokenProps={null}
        >
            <ClientProvider clientId={process.env.NEXT_PUBLIC_CROSSGUARD_CLIENT_ID}>
                <HeadProvider>
                    <OutsideClickProvider>
                        <Body>
                            <Component {...pageProps} />
                        </Body>
                    </OutsideClickProvider>
                </HeadProvider>
                <ToastContainer />
            </ClientProvider>
        </ContentStudioApiProvider>
    );
};

export default Html;
