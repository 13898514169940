import styled from 'styled-components';

import { COLORS } from '@constants/colors';

export const Container = styled.div`
    background: ${COLORS.pink};
    position: relative;

    .ql-editor * {
        color: white;
    }
`;

export const QuillContainer = styled.div``;

export const CloseButton = styled.button`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    padding-top: 10px;
    flex: 1;
    width: 100%;
    align-self: stretch;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
`;
