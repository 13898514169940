import React, { ReactNode, useState } from 'react';

import { useCookies } from 'react-cookie';

import { ILearner } from '@santeacademie/contentstudio-api-client';

interface ICookieStoreContext {
    learnerIdentifierCookie: ILearner;
    videoQualityCookie: number | undefined;
    mobileFirstBarCookie: boolean;
    addLearnerIdentifierCookie(learnerIdentifier: ILearner, maxAge: number): void;
    addMobileFirstBarCookie(mobileFirstBar: boolean, maxAge: number): void;
    addVideoQualityCookie(videoQuality: number, maxAge: number): void;
    removeLearnerIdentifierCookie(): void;
    removeVideoQualityCookie(): void;
    removeMobileFirstBarCookie(): void;
}

const CookieStoreContext = React.createContext<ICookieStoreContext>({} as ICookieStoreContext);
const { Provider, Consumer } = CookieStoreContext;

interface IProps {
    children: ReactNode;
}

const CookieStoreProvider = ({ children }: IProps): JSX.Element => {
    const [cookie, setCookie, removeCookie] = useCookies(['mobile_first_bar', 'learner_identifier', 'video_quality']);

    const [learnerIdentifierCookie, setLearnerIdentifierCookie] = useState<ILearner>(cookie.learner_identifier);
    const [mobileFirstBarCookie, setMobileFirstBarCookie] = useState<boolean>(cookie.mobile_first_bar === undefined);
    const [videoQualityCookie, setVideoQualityCookie] = useState<number | undefined>(
        isNaN(parseInt(cookie.video_quality)) ? undefined : parseInt(cookie.video_quality),
    );

    const addLearnerIdentifierCookie = (learnerIdentifier: ILearner, maxAge: number): void => {
        setLearnerIdentifierCookie(learnerIdentifier);
        setCookie('learner_identifier', JSON.stringify(learnerIdentifier), {
            path: '/',
            maxAge: maxAge,
            sameSite: true,
        });
    };

    const addMobileFirstBarCookie = (mobileFirstBar: boolean, maxAge: number): void => {
        setMobileFirstBarCookie(mobileFirstBar);
        setCookie('mobile_first_bar', cookie.mobile_first_bar ? parseInt(cookie.mobile_first_bar, 10) + 1 : 1, {
            maxAge: maxAge,
        });
    };

    const addVideoQualityCookie = (videoQuality: number, maxAge: number): void => {
        setVideoQualityCookie(videoQuality);
        setCookie('video_quality', videoQuality, { maxAge: maxAge });
    };

    const removeLearnerIdentifierCookie = (): void => {
        removeCookie('learner_identifier');
    };

    const removeVideoQualityCookie = (): void => {
        removeCookie('mobile_first_bar');
    };

    const removeMobileFirstBarCookie = (): void => {
        removeCookie('video_quality');
    };

    return (
        <Provider
            value={{
                learnerIdentifierCookie,
                mobileFirstBarCookie,
                videoQualityCookie,
                addLearnerIdentifierCookie,
                addMobileFirstBarCookie,
                addVideoQualityCookie,
                removeLearnerIdentifierCookie,
                removeVideoQualityCookie,
                removeMobileFirstBarCookie,
            }}
        >
            {children}
        </Provider>
    );
};

export { CookieStoreContext };
export default CookieStoreProvider;
export { Consumer as CookieStoreConsumer };
